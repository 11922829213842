.invalid-feedback {
  display: block !important;
}

.input-error {
  border-color: #dc3545 !important;
}

.input-error:focus {
  box-shadow: 0 0 0 0.2rem rgba(220,53,69,.25) !important;
}

/*
	===============================
			@Import	Function
	===============================
*/
/*
	===============================
			@Import	Mixins
	===============================
*/
.widget-content-area {
  -webkit-box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.0901961), 0 1px 20px 0 rgba(0, 0, 0, 0.08), 0px 1px 11px 0px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.0901961), 0 1px 20px 0 rgba(0, 0, 0, 0.08), 0px 1px 11px 0px rgba(0, 0, 0, 0.06);
  box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.0901961), 0 1px 20px 0 rgba(0, 0, 0, 0.08), 0px 1px 11px 0px rgba(0, 0, 0, 0.06); }

.no-content:before, .no-content:after {
  display: none !important; }

.new-control.new-checkbox .new-control-indicator {
  top: 0;
  height: 22px;
  width: 22px;
  border-radius: 50%; }

.table > thead {
  border-top: 1px solid #e0e6ed;
  border-bottom: 1px solid #e0e6ed; }

table.dataTable {
  border-collapse: separate;
  border-spacing: 0 5px;
  margin-top: 8px !important;
  margin-bottom: 50px !important;
  border-collapse: collapse !important; }
  table.dataTable thead .sorting:before, table.dataTable thead .sorting:after {
    opacity: 1;
    color: #d3d3d3; }
  table.dataTable thead .sorting_asc:before, table.dataTable thead .sorting_asc:after {
    opacity: 1;
    color: #d3d3d3; }
  table.dataTable thead .sorting_desc:before, table.dataTable thead .sorting_desc:after {
    opacity: 1;
    color: #d3d3d3; }
  table.dataTable thead .sorting_asc_disabled:before, table.dataTable thead .sorting_asc_disabled:after {
    opacity: 1;
    color: #d3d3d3; }
  table.dataTable thead .sorting_desc_disabled:before, table.dataTable thead .sorting_desc_disabled:after {
    opacity: 1;
    color: #d3d3d3; }
  table.dataTable thead .sorting_asc:before, table.dataTable thead .sorting_desc:after {
    color: #0e1726; }

.table > tbody:before {
  line-height: 1em;
  content: "_";
  color: white;
  display: block; }

.table > tbody tr {
  border-radius: 4px;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
  border-bottom: 1px solid #e0e6ed; }

.table > thead > tr > th {
  color: #1b55e2;
  font-weight: 700;
  font-size: 13px;
  border: none;
  letter-spacing: 1px;
  text-transform: uppercase; }

.table > tbody > tr > td {
  border: none;
  color: #888ea8;
  font-size: 13px;
  letter-spacing: 1px; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: transparent !important; }

.table > tfoot > tr > th {
  border: none; }

.table-hover:not(.table-dark) tbody tr:hover {
  background-color: transparent !important;
  -webkit-transform: translateY(-1px) scale(1.01);
  transform: translateY(-1px) scale(1.01); }

.table-hover.non-hover:not(.table-dark) tbody tr:hover {
  -webkit-transform: none;
  transform: none; }

div.dataTables_wrapper div.dataTables_info {
  padding-top: 0.85em;
  white-space: normal;
  color: #1b55e2;
  font-weight: 600;
  border: 1px solid #e0e6ed;
  display: inline-block;
  padding: 10px 16px;
  border-radius: 6px;
  font-size: 13px; }

div.dataTables_wrapper div.dataTables_filter label {
  position: relative; }

div.dataTables_wrapper div.dataTables_filter svg {
  position: absolute;
  top: 15px;
  right: 14px;
  width: 20px;
  color: #d3d3d3; }

.dataTables_wrapper .form-control {
  background: #fff;
  border: none;
  margin-top: 5px;
  -webkit-box-shadow: 2px 5px 17px 0 rgba(31, 45, 61, 0.1);
  box-shadow: 2px 5px 17px 0 rgba(31, 45, 61, 0.1);
  border-radius: 6px;
  border: 1px solid #e0e6ed;
  padding: 8px 30px 8px 14px; }

div.dataTables_wrapper div.dataTables_filter input {
  width: 150px; }

.dataTables_wrapper .dataTables_length select.form-control {
  padding: 8px 17px 8px 14px;
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  appearance: none;
  background: #fff url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24' fill='none' stroke='%23d3d3d3' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e") 50px 9px no-repeat; }

div.dataTables_wrapper div.dataTables_paginate {
  margin: 0;
  white-space: nowrap;
  text-align: right;
  display: inline-block; }

.page-link {
  margin-right: 5px;
  border-radius: 50%;
  padding: 8px 12px;
  background: rgba(0, 23, 55, 0.08);
  border: none;
  color: #888ea8; }
  .page-link:focus {
    box-shadow: none; }

div.dataTables_wrapper div.dataTables_paginate ul.pagination {
  margin: 3px 0; }

.page-item.disabled .page-link {
  background: #f1f2f3; }
  .page-item.disabled .page-link svg {
    color: #acb0c3; }

.page-item:first-child .page-link, .page-item:last-child .page-link {
  border-radius: 50%;
  padding: 5px 9px; }
  .page-item:first-child .page-link svg, .page-item:last-child .page-link svg {
    width: 17px; }

.page-item.active .page-link {
  background-color: #1b55e2; }

#alter_pagination_next a, #alter_pagination_previous a {
  border-radius: 50%;
  padding: 5px 9px; }

#alter_pagination_next a svg, #alter_pagination_previous a svg {
  width: 17px; }

.table-cancel {
  color: #888ea8;
  margin-right: 6px;
  vertical-align: middle;
  fill: rgba(0, 23, 55, 0.08);
  cursor: pointer; }

.table-hover:not(.table-dark) tbody tr:hover .table-cancel {
  color: #e7515a; }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
  .dataTables_wrapper .dataTables_length select.form-control {
    background: transparent;
    padding: 8px 10px 8px 14px; } }

@media (max-width: 767px) {
  div.dataTables_wrapper div.dataTables_info {
    margin-bottom: 40px; } }

/*
	===============================
			@Import	Function
	===============================
*/
/*
	===============================
			@Import	Mixins
	===============================
*/
.main-container, #content {
  min-height: auto; }

/*
    App Note Container
*/
.app-note-container {
  position: relative;
  display: flex; }
  .app-note-container .tab-title {
    max-width: 210px;
    width: 100%; }

.note-sidebar-scroll {
  position: relative;
  margin: auto;
  width: 100%;
  overflow: auto;
  height: calc(100vh - 202px); }

/*
    Group section
*/
.group-section {
  font-weight: 600;
  font-size: 15px;
  color: #3b3f5c;
  letter-spacing: 1px;
  margin-top: 25px;
  margin-bottom: 13px;
  padding: 9px 20px; }
  .group-section svg {
    color: #888ea8;
    margin-right: 6px;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    fill: rgba(0, 23, 55, 0.08); }

.app-note-overlay {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #3b3f5c !important;
  z-index: 4 !important;
  opacity: 0;
  transition: all 0.5s ease-in-out; }
  .app-note-overlay.app-note-overlay-show {
    display: block;
    opacity: .7; }

/*
    Tab Title
*/
.tab-title.mail-menu-show {
  left: 0;
  width: 100%;
  min-width: 190px;
  height: 100%; }

.tab-title hr {
  border-top: 1px solid #0e1726;
  max-width: 54px;
  margin-top: 25px;
  margin-bottom: 25px; }

.tab-title .nav-pills .nav-link.active, .tab-title .nav-pills .show > .nav-link {
  background-color: #bfc9d4;
  color: #3b3f5c;
  font-weight: 700; }

.tab-title .nav-pills a.nav-link {
  position: relative;
  font-weight: 700;
  color: #515365;
  padding: 9px 20px;
  cursor: pointer;
  font-size: 14px;
  border-radius: 6px; }
  .tab-title .nav-pills a.nav-link svg {
    margin-right: 7px;
    width: 18px;
    height: 18px;
    vertical-align: sub; }
  .tab-title .nav-pills a.nav-link .mail-badge {
    background: #c2d5ff;
    border-radius: 50%;
    position: absolute;
    right: 8px;
    padding: 4px 7px;
    height: 24px;
    width: 23px;
    color: #1b55e2;
    font-weight: 600; }

.tab-title .nav-pills.group-list .nav-item a {
  position: relative;
  font-size: 14px;
  font-weight: 700;
  padding: 9px 15px 9px 50px;
  color: #3b3f5c;
  letter-spacing: 1px; }
  .tab-title .nav-pills.group-list .nav-item a[class*="g-dot-"]:before {
    position: absolute;
    padding: 4px;
    content: '';
    border-radius: 50%;
    top: 14px;
    left: 20px;
    border: 2px solid #515365; }
  .tab-title .nav-pills.group-list .nav-item a.g-dot-danger:before {
    background: #ffe1e2;
    border: 1px solid #e7515a; }
  .tab-title .nav-pills.group-list .nav-item a.g-dot-primary:before {
    background: #bae7ff;
    border: 1px solid #2196f3; }
  .tab-title .nav-pills.group-list .nav-item a.g-dot-warning:before {
    background: #ffeccb;
    border: 1px solid #e2a03f; }
  .tab-title .nav-pills.group-list .nav-item a.g-dot-success:before {
    background: #dccff7;
    border: 1px solid #5c1ac3; }

.tab-title .nav-pills .nav-item .dropdown-menu {
  box-shadow: 0px 0px 15px 1px rgba(113, 106, 202, 0.2);
  padding: 0;
  border: none; }

.tab-title li.mail-labels a.dropdown-item {
  font-size: 13px;
  font-weight: 700;
  padding: 8px 18px; }
  .tab-title li.mail-labels a.dropdown-item:hover {
    background-color: #fff;
    color: #1b55e2; }

.tab-title li.mail-labels .label:after {
  position: absolute;
  content: "";
  height: 6px;
  width: 6px;
  border-radius: 50%;
  right: 15px;
  top: 43%; }

/*Mail Labels*/
/*
    Note container
*/
.note-container {
  padding: 0 15px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
  .note-container .switch {
    text-align: right;
    margin-bottom: 43px; }
    .note-container .switch .active-view {
      color: #1b55e2;
      fill: rgba(27, 85, 226, 0.239216); }
    .note-container .switch .view-list, .note-container .switch .view-grid {
      padding: 10px;
      background: #fff;
      border-radius: 5px;
      cursor: pointer;
      color: #515365;
      box-shadow: 0px 2px 4px rgba(126, 142, 177, 0.12);
      width: 43px;
      height: 41px;
      fill: rgba(0, 23, 55, 0.08); }
  .note-container.note-grid .note-item {
    position: relative;
    padding: 16px 16px;
    margin-right: 0;
    margin-left: 5px;
    margin-bottom: 18px;
    width: 100%;
    max-width: 300px;
    border-radius: 6px;
    display: inline-flex;
    background: #1b55e2; }
    .note-container.note-grid .note-item.note-personal {
      background: #bae7ff; }
    .note-container.note-grid .note-item.note-work {
      background: #ffeccb; }
    .note-container.note-grid .note-item.note-important {
      background: #ffe1e2; }
    .note-container.note-grid .note-item.note-social {
      background: #dccff7; }
    .note-container.note-grid .note-item .note-inner-content {
      border-radius: 4px;
      width: 100%;
      min-height: 163px; }
      .note-container.note-grid .note-item .note-inner-content .note-title {
        font-size: 19px;
        font-weight: 600;
        color: #fff;
        margin-bottom: 11px;
        letter-spacing: 0px; }
      .note-container.note-grid .note-item .note-inner-content .meta-time {
        font-weight: 600;
        margin-bottom: 10px;
        font-size: 14px;
        color: #fff;
        display: inline-block;
        border-radius: 4px; }
      .note-container.note-grid .note-item .note-inner-content .note-description {
        font-size: 15px;
        font-weight: 600;
        color: #fff;
        margin-bottom: 18px;
        letter-spacing: 0px;
        word-wrap: break-word; }
      .note-container.note-grid .note-item .note-inner-content .note-action {
        display: inline-block; }
        .note-container.note-grid .note-item .note-inner-content .note-action .fav-note, .note-container.note-grid .note-item .note-inner-content .note-action .delete-note {
          padding: 4px;
          border-radius: 5px;
          cursor: pointer;
          color: #515365;
          width: 28px;
          height: 28px;
          fill: rgba(0, 23, 55, 0.08); }
    .note-container.note-grid .note-item.note-fav .note-inner-content .note-action .fav-note {
      fill: #e2a03f; }
    .note-container.note-grid .note-item .note-inner-content .note-action .fav-note:hover {
      fill: #e2a03f; }
    .note-container.note-grid .note-item .note-inner-content .note-action .delete-note:hover {
      color: #e7515a; }
    .note-container.note-grid .note-item .note-inner-content .note-footer {
      display: inline-block;
      float: none; }
      .note-container.note-grid .note-item .note-inner-content .note-footer .tags {
        display: inline-block;
        position: relative;
        padding: 4px 6px;
        border-radius: 4px; }
        .note-container.note-grid .note-item .note-inner-content .note-footer .tags [class*="g-dot-"] {
          content: '';
          background: transparent;
          border-radius: 50%;
          border: 2px solid #acb0c3;
          display: inline-block;
          height: 11px;
          width: 11px;
          vertical-align: middle;
          display: none; }
        .note-container.note-grid .note-item .note-inner-content .note-footer .tags .g-dot-personal {
          background: #bae7ff;
          border: 2px solid #2196f3; }
        .note-container.note-grid .note-item .note-inner-content .note-footer .tags .g-dot-work {
          background: #ffeccb;
          border: 2px solid #e2a03f; }
        .note-container.note-grid .note-item .note-inner-content .note-footer .tags .g-dot-social {
          background: #dccff7;
          border: 2px solid #5c1ac3; }
        .note-container.note-grid .note-item .note-inner-content .note-footer .tags .g-dot-important {
          background: #ffe1e2;
          border: 2px solid #e7515a; }
    .note-container.note-grid .note-item.note-personal .note-inner-content .note-footer .tags .g-dot-personal, .note-container.note-grid .note-item.note-work .note-inner-content .note-footer .tags .g-dot-work, .note-container.note-grid .note-item.note-social .note-inner-content .note-footer .tags .g-dot-social, .note-container.note-grid .note-item.note-important .note-inner-content .note-footer .tags .g-dot-important {
      display: inline-block; }
    .note-container.note-grid .note-item .note-inner-content .note-footer .tags-selector {
      display: inline-block; }
      .note-container.note-grid .note-item .note-inner-content .note-footer .tags-selector .dropdown-menu {
        min-width: 8rem; }
        .note-container.note-grid .note-item .note-inner-content .note-footer .tags-selector .dropdown-menu a {
          font-size: 14px;
          padding: 3px 35px;
          font-weight: 600;
          letter-spacing: 0px;
          color: #3b3f5c; }
        .note-container.note-grid .note-item .note-inner-content .note-footer .tags-selector .dropdown-menu .dropdown-item.active, .note-container.note-grid .note-item .note-inner-content .note-footer .tags-selector .dropdown-menu .dropdown-item:active {
          background: transparent; }
        .note-container.note-grid .note-item .note-inner-content .note-footer .tags-selector .dropdown-menu [class*="g-dot-"]:before {
          content: '';
          position: absolute;
          padding: 4px;
          border-radius: 50%;
          top: 9px;
          left: 10px;
          border: 2px solid #515365; }
        .note-container.note-grid .note-item .note-inner-content .note-footer .tags-selector .dropdown-menu a.g-dot-important:before {
          background: #ffe1e2;
          border: 1px solid #e7515a; }
        .note-container.note-grid .note-item .note-inner-content .note-footer .tags-selector .dropdown-menu a.g-dot-personal:before {
          background: #bae7ff;
          border: 1px solid #2196f3; }
        .note-container.note-grid .note-item .note-inner-content .note-footer .tags-selector .dropdown-menu a.g-dot-work:before {
          background: #ffeccb;
          border: 1px solid #e2a03f; }
        .note-container.note-grid .note-item .note-inner-content .note-footer .tags-selector .dropdown-menu a.g-dot-social:before {
          background: #dccff7;
          border: 1px solid #5c1ac3; }
    .note-container.note-grid .note-item .note-inner-content .note-footer .nav-link {
      padding: 0; }
      .note-container.note-grid .note-item .note-inner-content .note-footer .nav-link span {
        display: block; }
      .note-container.note-grid .note-item .note-inner-content .note-footer .nav-link .feather-more-vertical {
        border-radius: 5px;
        cursor: pointer;
        color: #515365;
        width: 20px;
        height: 20px; }
        .note-container.note-grid .note-item .note-inner-content .note-footer .nav-link .feather-more-vertical:hover {
          color: #0e1726; }
    .note-container.note-grid .note-item.note-personal .note-inner-content .note-footer .nav-link .feather-more-vertical, .note-container.note-grid .note-item.note-work .note-inner-content .note-footer .nav-link .feather-more-vertical, .note-container.note-grid .note-item.note-social .note-inner-content .note-footer .nav-link .feather-more-vertical, .note-container.note-grid .note-item.note-important .note-inner-content .note-footer .nav-link .feather-more-vertical {
      display: none; }

/* 
    Note Container
*/
/*
=====================
    Modal Content
=====================
*/
.modal-content {
  border: none;
  box-shadow: 0px 0px 15px 1px rgba(113, 106, 202, 0.2); }
  .modal-content .modal-footer {
    border-top: none; }
    .modal-content .modal-footer [data-dismiss="modal"] {
      background-color: #fff;
      color: #1b55e2;
      font-weight: 700;
      border: 1px solid #e8e8e8;
      padding: 10px 25px; }
    .modal-content .modal-footer .btn[data-dismiss="modal"] svg {
      margin-right: 5px;
      width: 18px;
      vertical-align: bottom; }
    .modal-content .modal-footer #btn-n-add {
      background-color: #1b55e2;
      color: #fff;
      font-weight: 600;
      border: 1px solid #1b55e2;
      padding: 10px 25px; }
    .modal-content .modal-footer #btn-n-save {
      font-weight: 600;
      padding: 10px 25px; }

.modal-backdrop {
  background-color: #ebedf2; }

.modal-content svg.close {
  position: absolute;
  right: -7px;
  top: -8px;
  font-size: 12px;
  font-weight: 600;
  padding: 3px;
  background: #fff;
  border-radius: 5px;
  opacity: 1;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: .600s;
  color: #3b3f5c; }

.notes-box .notes-content form .note-title {
  padding-top: 20px; }

.notes-box .notes-content form .note-description {
  padding-top: 40px; }

/*
    ===============
        Note Box
    ===============
*/
.hamburger {
  display: none; }

/*
    Media Query
*/
@media (max-width: 2132px) {
  .note-container.note-grid .note-item {
    max-width: 100%; } }

@media (max-width: 1199px) {
  .note-container.note-grid .note-item {
    max-width: 48%; } }

@media (max-width: 991px) {
  .app-notes {
    margin-top: 37px; }
  .app-note-container .tab-title {
    position: absolute;
    z-index: 4;
    left: -170px;
    width: 0; }
  .tab-title.note-menu-show {
    left: 0;
    width: 100%;
    min-width: 190px;
    min-height: 485px;
    border-radius: 0;
    border-bottom-right-radius: 6px;
    padding: 11px;
    background: #fff; }
  .note-sidebar-scroll {
    height: 100%; }
  .app-hamburger-container {
    text-align: right; }
  .hamburger {
    position: relative;
    top: -13px;
    padding: 6px 9px 6px 9px;
    font-size: 20px;
    color: #fff;
    align-self: center;
    display: inline-block;
    background-color: #515365;
    border-radius: 50%; } }

@media (max-width: 767px) {
  .note-container.note-grid .note-item {
    max-width: 47%; } }

@media (max-width: 575px) {
  .note-container.note-grid .note-item {
    max-width: 100%;
    margin-left: 0; } }

.full-page-loader {
  display: flex;
  align-items: center;
  justify-content:  center;
  height: 100vh;
}

.loader-et-lg {
  width: 5rem !important;
  height: 5rem !important;
}

.cover-full-page-loader {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: #e4e4e4d4;
  z-index: 10000;
  display: flex;
  justify-content: center;
}
/*
	===============================
			@Import	Function
	===============================
*/
/*
	===============================
			@Import	Mixins
	===============================
*/
.widget-content .row.padding35 .col-md-4 {
	margin-bottom: 20px;
}

.btn {
	margin-right: 5px;
}
	
.swal2-show {
	padding: 2em !important;
}

.temp-avatar {
  width: 80px;
  padding: 12px;
}
.temp-basic-info-cont {
  display: flex;
  align-items: center;
  justify-content: center;
}
.temp-basic-info {
  max-width: 100% !important;
}
.temp-basic-external-links {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 46px;
  margin-bottom: 0px;
}
.temp-skill-tag {
  margin-bottom: 10px;
  margin-right: 10px;
}
.temp-doc {
  flex: 1;
}
.temp-download-icon {
  width: 30px;
}
.temp-doc-cont {
  cursor: pointer;
}
.temp-doc-cont:hover {
  transform: scale(1.02);
}
.temp-notes-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.note-input-box {
  margin-top: 32px;
}
.note-title-action {
  display: flex;
  justify-content: space-between;
}
iframe{
  width: 830px;
}
.candidate_popup_padding{
  padding-top: 0px;
  padding-bottom: 0px;
}
/* .css-1kvvgjv-MuiSvgIcon-root-MuiSelect-icon{

} */
.create_form_control{
    padding: 30px 20px;
    background-color:#F1F2F3;
    border-radius: 4px;
    /* position: absolute;
    z-index: 2;
    width: 94%; */
}
.main_container{
    padding-top: 25px;
}
.select_box{
    width: 250px;
    height: 30px;
    border-radius: 4px;
    border: 2px solid gray;
    margin-top: 10px;

}
.create_job_textarea{
    width: 250px;
    height: 100px;
    border-radius: 4px;
    border: 2px solid gray;
}
.parent_select_box{
    padding: 5px 0px;
}
.experience_to_lable{
    margin-left: 10px;
}
.radio_box{
   font-size: 1rem;
}
.info_box-1{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 190px;
}

.info_box-2{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 240px;
}
.job_description{
    padding-left:0px;
}

.option_box{
padding: 10px 0px;
font-size: 18px;
}


.error_code{
    color: red;
    font-size: 60px;
    word-spacing: 7px;
}
.error_message {
    font-size: 45px;

}
.error_container{
    text-align: center ;
    padding-top: 120px;
}
.disable{
    pointer-events: none;
    opacity: 0.5;

}

/* media query */
@media (max-width: 375px) {
    
    /* .sm-width{
        width: 50%;
    } */
  }
  /* dash bolrd style */
  .dashboard_design{
      display: flex;
      justify-content: space-between;
      align-items: center;
  }
  .notes_fiels{
      display: flex;
      justify-content: space-between;
  }
  .only_first_time_show{
    display: flex;
    justify-content: space-between;
  }
/* user list */
.pagination{
    flex-wrap: nowrap;
}
/* for toggle */

 /* toggle in label designing */
 
 /* .toggle {
    position : relative ;
    display : inline-block;
    width : 50px;
    height : 25px;
    background-color: red;
    border-radius: 30px;
    border: 2px solid gray;
}
        */
/* After slide changes */
/* .toggle::before {
    content: '';
    position: absolute;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: gray;
    top: .4px;
    left: 1px;
    transition:  all 0.5s;
} */
       
/* Toggle text */

       
/* Checkbox checked effect */
/* .checkbox:checked + .toggle::before {
    left : 23px;
} */

/* .checkbox:checked + .toggle::before {
    left : 23px;
} */
       
/* Checkbox checked toggle label bg color */
/* .checkbox:checked + .toggle {
    background-color: green;
}
        */
/* Checkbox vanished */
/* .checkbox {
    display : none;
} */

.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    background-color: rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    transition: all 0.3s;
  }
  
  .switch::after {
    content: '';
    position: absolute;
    width: 18px;
    height: 18px;
    border-radius: 18px;
    background-color: white;
    top: 1px;
    left: 1px;
    transition: all 0.3s;
  }
  
  input[type='checkbox']:checked + .switch::after {
    transform: translateX(20px);
  }
  
  input[type='checkbox']:checked + .switch {
    background-color: #7983ff;
  }
  
  .offscreen {
    position: absolute;
    left: -9999px;
  }
  /* updated ui */
  .btn-left{
      margin-left: 15px;
  }
  .form-row-btn{
      display: flex;
      justify-content: space-between;
      width: 15%;
  }
  .job-col-width{
      max-width: 90%;
  }
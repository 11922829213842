/*
	===============================
			@Import	Function
	===============================
*/
/*
	===============================
			@Import	Mixins
	===============================
*/
.widget-content-area {
  -webkit-box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.0901961), 0 1px 20px 0 rgba(0, 0, 0, 0.08), 0px 1px 11px 0px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.0901961), 0 1px 20px 0 rgba(0, 0, 0, 0.08), 0px 1px 11px 0px rgba(0, 0, 0, 0.06);
  box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.0901961), 0 1px 20px 0 rgba(0, 0, 0, 0.08), 0px 1px 11px 0px rgba(0, 0, 0, 0.06); }

.layout-spacing {
  padding-bottom: 25px; }

/* 
===================
    User Profile
===================
*/
.user-profile .widget-content-area {
  border-radius: 6px; }
  .user-profile .widget-content-area .edit-profile {
    height: 35px;
    width: 35px;
    display: flex;
    justify-content: center;
    align-self: center;
    background-color: #1b55e2;
    background: linear-gradient(to right, #0081ff 0%, #0045ff 100%);
    border-radius: 50%;
    box-shadow: 0 10px 15px rgba(0, 69, 255, 0.2); }
    .user-profile .widget-content-area .edit-profile svg {
      font-size: 17px;
      vertical-align: middle;
      margin-right: 0;
      color: #fff;
      width: 19px;
      align-self: center; }
  .user-profile .widget-content-area h3 {
    position: relative;
    font-size: 21px;
    font-weight: 600;
    color: #3b3f5c;
    margin: 6px 0px 0 0; }
    .user-profile .widget-content-area h3:after {
      position: absolute;
      content: "";
      height: 2px;
      width: 55px;
      background: #1b55e2;
      border-radius: 50%;
      bottom: -2px;
      left: 15px; }
  .user-profile .widget-content-area .user-info {
    margin-top: 40px; }
    .user-profile .widget-content-area .user-info img {
      border-radius: 9px;
      box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2); }
    .user-profile .widget-content-area .user-info p {
      font-size: 20px;
      font-weight: 600;
      margin-top: 22px;
      color: #1b55e2; }
  .user-profile .widget-content-area .user-info-list ul.contacts-block {
    border: none;
    max-width: 217px;
    margin: 36px auto; }
    .user-profile .widget-content-area .user-info-list ul.contacts-block li {
      margin-bottom: 13px;
      font-weight: 600;
      font-size: 13px; }
      .user-profile .widget-content-area .user-info-list ul.contacts-block li a {
        font-weight: 600;
        font-size: 15px;
        color: #1b55e2; }
    .user-profile .widget-content-area .user-info-list ul.contacts-block svg {
      width: 21px;
      margin-right: 15px;
      color: #888ea8;
      vertical-align: middle;
      fill: rgba(0, 23, 55, 0.08); }
    .user-profile .widget-content-area .user-info-list ul.contacts-block li:hover svg {
      color: #1b55e2;
      fill: rgba(27, 85, 226, 0.239216); }
    .user-profile .widget-content-area .user-info-list ul.contacts-block ul.list-inline {
      margin: 27px auto; }
      .user-profile .widget-content-area .user-info-list ul.contacts-block ul.list-inline div.social-icon {
        border: 2px solid #e0e6ed;
        border-radius: 50%;
        height: 35px;
        width: 35px;
        display: flex;
        justify-content: center;
        align-self: center; }
      .user-profile .widget-content-area .user-info-list ul.contacts-block ul.list-inline svg {
        margin-right: 0;
        color: #1b55e2;
        width: 19px;
        align-self: center; }

/* 
==============
    Skills
==============
*/
.skills .widget-content-area {
  border-radius: 6px; }
  .skills .widget-content-area h3 {
    position: relative;
    font-size: 21px;
    font-weight: 600;
    color: #3b3f5c;
    margin: 6px 0px 40px 0; }
    .skills .widget-content-area h3:after {
      position: absolute;
      content: "";
      height: 2px;
      width: 47px;
      background: #1b55e2;
      border-radius: 50%;
      bottom: -2px;
      left: 15px; }
  .skills .widget-content-area p.skill-item {
    margin-bottom: 5px;
    font-size: 15px;
    color: #515365;
    font-weight: 600; }
  .skills .widget-content-area .progress {
    height: 20px;
    box-shadow: 1px 3px 20px 3px #f1f2f3;
    margin-bottom: 31px; }
    .skills .widget-content-area .progress .progress-bar {
      background-color: #1b55e2;
      background: linear-gradient(to right, #0081ff 0%, #0045ff 100%);
      border-radius: 1rem;
      box-shadow: 0 2px 4px rgba(0, 69, 255, 0.15), 0 8px 16px rgba(0, 69, 255, 0.2);
      font-size: 12px;
      letter-spacing: 1px;
      font-weight: 100; }
    .skills .widget-content-area .progress .progress-title {
      display: flex;
      justify-content: space-between;
      padding: 15px; }
      .skills .widget-content-area .progress .progress-title span {
        align-self: center; }

/* 
==============
    Bio
==============
*/
.bio .widget-content-area {
  border-radius: 6px;
  padding-bottom: 0; }
  .bio .widget-content-area h3 {
    position: relative;
    font-size: 21px;
    font-weight: 600;
    color: #3b3f5c;
    margin: 6px 0px 40px 0; }
    .bio .widget-content-area h3:after {
      position: absolute;
      content: "";
      height: 2px;
      width: 30px;
      background: #1b55e2;
      border-radius: 50%;
      bottom: -2px;
      left: 15px; }
  .bio .widget-content-area h5 {
    font-size: 16px;
    font-weight: 700;
    color: #3b3f5c; }
  .bio .widget-content-area p {
    color: #888ea8;
    font-size: 15px; }
  .bio .widget-content-area .bio-skill-box {
    padding-top: 30px;
    padding-bottom: 30px; }
  .bio .widget-content-area .b-skills {
    padding: 22px 16px;
    box-shadow: 2px 5px 17px 0 rgba(31, 45, 61, 0.17);
    background: #fff;
    border-radius: 10px;
    padding: 30px 30px 24px; }
    .bio .widget-content-area .b-skills svg {
      width: 30px;
      height: 30px;
      margin-right: 21px;
      color: #1b55e2; }
    .bio .widget-content-area .b-skills h5 {
      margin-bottom: 10px;
      font-size: 16px; }
    .bio .widget-content-area .b-skills p {
      font-size: 13px; }

/* 
================
    Education
================
*/
.education .widget-content-area {
  border-radius: 6px; }
  .education .widget-content-area h3 {
    position: relative;
    font-size: 21px;
    font-weight: 600;
    color: #3b3f5c;
    margin: 6px 0px 40px 0; }
    .education .widget-content-area h3:after {
      position: absolute;
      content: "";
      height: 2px;
      width: 97px;
      background: #1b55e2;
      border-radius: 50%;
      bottom: -2px;
      left: 15px; }
  .education .widget-content-area .timeline-alter .item-timeline {
    display: flex; }
    .education .widget-content-area .timeline-alter .item-timeline .t-dot {
      position: relative;
      border-color: #1b55e2;
      padding: 10px; }
      .education .widget-content-area .timeline-alter .item-timeline .t-dot:before {
        content: '';
        position: absolute;
        border-color: inherit;
        border-width: 2px;
        border-style: solid;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        top: 15px;
        left: 50%;
        transform: translateX(-50%); }
    /* .education .widget-content-area .timeline-alter .item-timeline:not(:last-child) .t-dot {
      content: '';
      position: absolute;
      border-color: inherit;
      border-width: 2px;
      border-style: solid;
      border-radius: 50%;
      width: 10px;
      height: 10px;
      top: 15px;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: auto;
      top: 25px;
      bottom: -15px;
      border-right-width: 0;
      border-top-width: 0;
      border-bottom-width: 0;
      border-radius: 0;
      border-color: #ebedf2; } */
    .education .widget-content-area .timeline-alter .item-timeline .t-dot img {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      z-index: 7;
      position: relative; }
    .education .widget-content-area .timeline-alter .item-timeline .t-meta-date {
      padding: 10px; }
      .education .widget-content-area .timeline-alter .item-timeline .t-meta-date p {
        margin: 0;
        min-width: 100px;
        max-width: 100px;
        font-size: 12px;
        font-weight: 700;
        color: #888ea8; }
    .education .widget-content-area .timeline-alter .item-timeline .t-text {
      padding: 10px;
      align-self: center; }
      .education .widget-content-area .timeline-alter .item-timeline .t-text p {
        font-size: 13px;
        margin: 0;
        color: #515365;
        font-weight: 600; }
        .education .widget-content-area .timeline-alter .item-timeline .t-text p a {
          color: #2196f3;
          font-weight: 600; }

@media (max-width: 575px) {
  /* .education .widget-content-area .timeline-alter .item-timeline {
    display: block;
    text-align: center; } */
    .education .widget-content-area .timeline-alter .item-timeline .t-meta-date p, .education .widget-content-area .timeline-alter .item-timeline .t-usr-txt p {
      margin: 0 auto; } }

/* 
=======================
    Work Experience
=======================
*/
.work-experience .widget-content-area {
  border-radius: 6px; }
  .work-experience .widget-content-area h3 {
    position: relative;
    font-size: 21px;
    font-weight: 600;
    color: #3b3f5c;
    margin: 6px 0px 40px 0; }
    .work-experience .widget-content-area h3:after {
      position: absolute;
      content: "";
      height: 2px;
      width: 173px;
      background: #1b55e2;
      border-radius: 50%;
      bottom: -2px;
      left: 15px; }
  .work-experience .widget-content-area .timeline-alter .item-timeline {
    display: flex; }
    .work-experience .widget-content-area .timeline-alter .item-timeline .t-dot {
      position: relative;
      border-color: #1b55e2;
      padding: 10px; }
      .work-experience .widget-content-area .timeline-alter .item-timeline .t-dot:before {
        content: '';
        position: absolute;
        border-color: inherit;
        border-width: 2px;
        border-style: solid;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        top: 15px;
        left: 50%;
        transform: translateX(-50%); }
    /* .work-experience .widget-content-area .timeline-alter .item-timeline:not(:last-child) .t-dot:after {
      content: '';
      position: absolute;
      border-color: inherit;
      border-width: 2px;
      border-style: solid;
      border-radius: 50%;
      width: 10px;
      height: 10px;
      top: 15px;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: auto;
      top: 25px;
      bottom: -15px;
      border-right-width: 0;
      border-top-width: 0;
      border-bottom-width: 0;
      border-radius: 0;
      border-color: #ebedf2; } */
    .work-experience .widget-content-area .timeline-alter .item-timeline .t-dot img {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      z-index: 7;
      position: relative; }
    .work-experience .widget-content-area .timeline-alter .item-timeline .t-meta-date {
      padding: 10px; }
      .work-experience .widget-content-area .timeline-alter .item-timeline .t-meta-date p {
        margin: 0;
        min-width: 100px;
        max-width: 100px;
        font-size: 12px;
        font-weight: 700;
        color: #888ea8; }
        .work-experience .widget-content-area .timeline-alter .item-timeline .t-meta-date p.label {
          min-width: inherit;}
          .work-experience .widget-content-area .timeline-alter .item-timeline .t-text ul,ol{
            padding: 0 0 0 25px;}
    .work-experience .widget-content-area .timeline-alter .item-timeline .t-text {
      padding: 10px;
      align-self: center; }
      .work-experience .widget-content-area .timeline-alter .item-timeline .t-text p {
        font-size: 13px;
        margin: 0;
        color: #515365;
        font-weight: 500; }
        .work-experience .widget-content-area .timeline-alter .item-timeline .t-text p a {
          color: #2196f3;
          font-weight: 600; }

@media (max-width: 575px) {
  /* .work-experience .widget-content-area .timeline-alter .item-timeline {
    display: block;
    text-align: center; } */
    .work-experience .widget-content-area .timeline-alter .item-timeline .t-meta-date p, .work-experience .widget-content-area .timeline-alter .item-timeline .t-usr-txt p {
      margin: 0 auto; }
    canvas{
      /* width: 280px; */
      margin-left: -1rem;
    } 
  .react-pdf__Page__canvas{
    width: 280px;
    /* height: 350px; */
      margin-left: -1rem;
  }}

   

/*
	===============================
			@Import	Function
	===============================
*/
/*
	===============================
			@Import	Mixins
	===============================
*/
.widget-content-area {
  box-shadow: none !important; }

table.dataTable {
  border-collapse: separate !important;
  border-spacing: 0 5px;
  margin-top: 50px !important;
  margin-bottom: 50px !important;
  border-collapse: collapse !important; }

.table-hover:not(.table-dark) tbody tr td:first-child {
  border-left: none !important;
  border-left: none !important; }

.table-hover:not(.table-dark) tbody tr:hover .new-control.new-checkbox .new-control-indicator {
  border: 2px solid #1b55e2; }

/*Style. 1*/
.style-1 .user-name {
  font-size: 15px;
  font-weight: 600;
  color: #e2a03f; }

.style-1 .profile-img img {
  width: 50px;
  height: 50px;
  border: 2px solid #d3d3d3;
  border-radius: 6px;
  box-shadow: 0px 0px 14px 3px rgba(126, 142, 177, 0.24); }

/*Style. 2*/
.style-2 .new-control.new-checkbox .new-control-indicator {
  top: 1px; }

.style-2 .user-name {
  font-size: 15px;
  font-weight: 600;
  color: #e2a03f; }

.style-2 img.profile-img {
  width: 50px;
  height: 50px;
  border: 2px solid #d3d3d3;
  box-shadow: 0px 0px 14px 3px rgba(126, 142, 177, 0.24); }

.style-2 .badge {
  background: transparent;
  transform: none; }

.style-2 .badge-primary {
  color: #1b55e2;
  border: 2px dashed #1b55e2; }

.style-2 .badge-warning {
  color: #e2a03f;
  border: 2px dashed #e2a03f; }

.style-2 .badge-danger {
  color: #e7515a;
  border: 2px dashed #e7515a; }

/*Style. 3*/
.style-3 .new-control.new-checkbox .new-control-indicator {
  top: 1px; }

.style-3 .user-name {
  font-size: 15px;
  font-weight: 600;
  color: #e2a03f; }

.style-3 img.profile-img {
  width: 50px;
  height: 50px;
  border: 2px solid #d3d3d3;
  border-radius: 6px;
  box-shadow: 0px 0px 14px 3px rgba(126, 142, 177, 0.24); }

.style-3 .badge {
  background: transparent;
  transform: none; }

.style-3 .badge-primary {
  color: #1b55e2;
  border: 2px dashed #1b55e2; }

.style-3 .badge-warning {
  color: #e2a03f;
  border: 2px dashed #e2a03f; }

.style-3 .badge-danger {
  color: #e7515a;
  border: 2px dashed #e7515a; }

.style-3 .table-controls {
  padding: 0; }
  .style-3 .table-controls li {
    list-style: none;
    display: inline; }
    .style-3 .table-controls li svg {
      color: #888ea8;
      vertical-align: middle;
      width: 28px;
      height: 28px;
      fill: rgba(0, 23, 55, 0.08);
      cursor: pointer; }

.style-3.table-hover:not(.table-dark) tbody tr:hover .table-controls li svg {
  color: #e7515a;
  fill: rgba(231, 81, 90, 0.219608); }

.style-3.table-hover:not(.table-dark) tbody tr:hover td:first-child {
  color: #1b55e2 !important; }
